import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import {
  navigation,
  active,
} from "../assets/scss/components/Navigation.module.scss";

const Navigation: React.FC = () => {
  return (
    <div className={navigation}>
      <Link to="/" activeClassName={active}>
        About
      </Link>
    </div>
  );
};

export default Navigation;
